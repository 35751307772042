.Game4{
    width: 100%;
    height: 100%;
    z-index: 9999999;
}

.CanvasGame4{
    position:absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 99999999;
}

.Game4 header{
    position:absolute;
    top: 0px;
    left: 50%;
    transform: translateX(-50%) scale(1);
    width: 622px;
    height: 99px;
    z-index: 9999999;
    background-repeat: no-repeat;
    background-size: contain;
}

.Game4 header .line{
    position:absolute;
    display: block;
    top: 40px;
    left: 50%;
    height: 6px;
    width: 330px;
    transform: translateX(-50%) scale(1);
    background-color: #fff;
    z-index: 9998;
}

.Game4 header .stars{
    z-index: 9999;
    position:absolute;
    top: 10px;
    left: 50%;
    width: 600px;
    text-align: center;
    transform: translateX(-50%) scale(1);
}

.Game4 header .stars img{
    margin-left: 40px;
    margin-right: 40px;
}


.Game4 #homeButton{
    position:absolute;
    top: 50px;
    left: 70px;
    transform: translate(-50%, -50%) scale(1);
    border: none;
    background-color: transparent;
    width: 76px;
    height: 76px;
    z-index: 9999;
    outline: none !important;
}

.a-loader-title {
    color: #c0d58a;
}

.swal2-popup, .swal2-modal{
    color:#351e00 !important;    
    background:  url("../../assets/videowindow.png")  no-repeat !important;
    background-size: 100% 100% !important;
    padding: 30px 60px 30px 60px;
}

.swal2-confirm, .swal2-deny{
    color:#351e00 !important;
    font-family: 'FinkHeavy' !important;  
    font-weight: bold !important;
    background-color: transparent !important;
    background: transparent url("../../assets/btn.png") !important;
    background-size: 100% 100% !important;
    background-repeat: no-repeat;
    border: none !important;
    outline: none !important;
}

.swal2-button:hover, .swal2-button:active, .swal2-button:focus{
    background-color: transparent !important;
    border: none !important;
    outline: none !important;
}

.swal2-icon--info:after, .swal2-icon--info:before{
    background-color: #351e00 !important;
}

.swal2-icon.swal2-info{
    border-color: #351e00 !important;
    color: #351e00 !important;
}

.swal2-icon--warning__body, .swal2-icon--warning__dot{
    background-color: #351e00 !important;
}

.swal2-icon.swal2-question{
    border-color: #351e00 !important;
    color: #351e00 !important;
}

.swal2-icon.swal2-success{
    border-color: #351e00 !important;
    color: #351e00 !important;
}

.swal2-footer {
    text-align: center !important;
}

.swal2-icon--success__hide-corners, .swal2-icon--success:after, .swal2-icon--success:before {
    background-color: transparent !important;
}
.swal2-icon--success, .swal2-icon--warning{
    border-color: #351e00 !important;
}

.swal2-icon.swal2-success [class^=swal2-success-line]{
    background-color:#351e00 !important;
}