.Game2{
    position:absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(1);
    z-index: 9999999;
}

.Game2 #homeButton{
    position:absolute;
    transform: translate(-50%, -50%);
    border: none;
    background-color: transparent;
    width: 76px;
    height: 76px;
    z-index: 9999;
}

.Game2 #game2Ayuda{
    position:absolute;
    left: 50%;
    top: 50%;
    height: 98%;
    transform: translate(-50%, -50%);
    z-index: 9999999;
    pointer-events:all;
}