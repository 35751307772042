.Dificultad .buttons{
    padding: 4px;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 60%;
    left: 50%;
    z-index: 999;
    width: 155px;
}

.Dificultad{
    position:absolute;
    left: 50%;
    top: 50%;
    width: 960px;
    height: 640px;
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 9999999;
}

.Dificultad .content .bg{
    position:absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
    z-index: 99;
}

.Dificultad .content{
    padding: 4px;
    margin:10px;
    border-radius:25px;
    height:600px;    
}

.Dificultad .buttons button{
    width: 151px;
    height:78px;
    background-size: contain;
    background-repeat: no-repeat;
}


.Dificultad canvas{
    position:absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}