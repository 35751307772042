
.Datos{
    position:absolute;
    left: 50%;
    top: 50%;
    width: 960px;
    height: 640px;
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 9999999;
}

.Datos .content{
    padding: 4px;
    margin:10px;
    border-radius:25px;
    height:600px;
    
}

.Datos .content h1{
    margin-top:180px;
}

.Datos .content .bg{
    position:absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
}


.Datos .content .formulario{
    position:absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
}



.Datos .content input{
    position:relative;
    top:70px;
    left: -20px;
    font-size: 20px;
    width: 380px;
    border-radius:25px;
    border: none;
    text-align: center;
    color: #351e00;
    font-family: 'FinkHeavy';  
    background-color: transparent;
    
}

.Datos .content button{
    position:relative;
    top: 130px;
    left: -20px;
    width: 151px;
    height:78px;
    background-size: contain;
    background-repeat: no-repeat;
}

.Datos canvas{
    position:absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}