
#tutorialWindow {
    position: absolute;
    width: 100vw;
    height: 100vh;
    top:50%;
    left:50%;
    z-index: 99999;
    transform: translate(-50%, -50%);
    background-color: rgba(47, 56, 27, 0.8);
}

#tutorialWindow #tutorialContainer {
    width: 741px;
    height: 537px;
    z-index: 99997;
    position: relative;
    margin: auto;

}

#tutorialWindow #skipButton{
    width: 102px; 
    height:53px; 
    position: absolute;
    bottom: 30px;
    left: calc(50% - 51px);
    background-repeat: no-repeat;
    background-size: contain;
    z-index: 99997;
}

#tutorialWindow #tutorialSlide {
    position: absolute;
    width: 670px;
    height: 350px;
    top:50%;
    left:50%; 
    background-position: center center;   
    transform: translate(-50%, -50%) scale(1);
    z-index: 99997;
    overflow: hidden;
}

#tutorialWindow #nav_dots
{
	position: absolute;
	bottom: 15px;
	z-index: 99998;
    width: 100%;
    text-align: center;
}

#tutorialWindow .nav_dot
{
    display: inline-block;
	width: 6px;
	height: 6px;
	border: 1px solid #fff;
	margin: 0px 5px;	
	border-radius: 50%;    
}

#tutorialWindow .nav_dot.active
{
    background-color: #fff; 
}