#Settings{
    position:absolute;
    z-index: 99999;
    width: 61px;
    height:67px;
    transform:translate(-50%, -50%) scale(1);
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;
}

#settingsButton{
    position:absolute;
    z-index: 99999;
    width: 61px;
    height:67px;
    transform:translate(-50%, -50%) scale(1);
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;
}

#settingsOptions{
    position:absolute;
    z-index: 9999;
    height:37px;
    transform:translate(0%, -50%) scale(1);
    background-size: contain;
    background-repeat: no-repeat;
    width: 350px;
    margin-left: 0px;
    margin-bottom: 0px;
    padding-top:18px;
    padding-left: 40px;
    border-radius: 20px;
    background-color: rgba(255, 255, 255, 0.5)
}

#settingsOptions .option{
    display: inline-block;
    margin-right: 20px;
    cursor: pointer;
    margin-top: -20px;
    padding: 20px 0px;
}

#settingsOptions .option span{
    color: #42ac47 !important;
}

.selected{
    color: #fff !important;
}