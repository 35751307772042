@font-face {font-family: "FinkHeavy";
  src: url("assets/font-face/cd1810fafebbd3df4964112052edf871.eot"); /* IE9*/
  src: url("assets/font-face/cd1810fafebbd3df4964112052edf871.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("assets/font-face/cd1810fafebbd3df4964112052edf871.woff2") format("woff2"), /* chrome、firefox */
  url("assets/font-face/cd1810fafebbd3df4964112052edf871.woff") format("woff"), /* chrome、firefox */
  url("assets/font-face/cd1810fafebbd3df4964112052edf871.ttf") format("truetype"), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
  url("assets/font-face/cd1810fafebbd3df4964112052edf871.svg#FinkHeavy") format("svg"); /* iOS 4.1- */
}

* {
  -webkit-tap-highlight-color:  rgba(0,0,0,0); 
  -webkit-touch-callout: none;
    -webkit-user-select: none;
     -khtml-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
            outline: none;
}

*:focus{
  -webkit-tap-highlight-color:  rgba(0,0,0,0); 
  -webkit-touch-callout: none;
    -webkit-user-select: none;
     -khtml-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
            outline: none;
}

body {
  margin: 0;
  padding: 0;
  user-select: none !important;
  overflow: hidden;
  font-family: 'FinkHeavy';  
  background-color: #c0d58a;
}

#root{
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: #c0d58a;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  user-select: none !important;
}

.generalBtn{
  z-index: 99999;
  width: 73px;
  height: 48px;
  background-repeat: no-repeat;
  background-size: contain;
}

button{
  border:none;
  background-color: transparent;
}

canvas{
  user-select: none !important;
}

#overlay_left{
  width:111.5px;
  height: 100%;
  background-color: rgba(47, 56, 27, 0.8);
  position: absolute;
  left: 0px;
  display: none;
  z-index: 9999999;
}

#overlay_right{
  width:111.5px;
  height: 100%;
  background-color: rgba(47, 56, 27, 0.8);
  position: absolute;
  right: 0px;
  display: none;
  z-index: 9999999;
}

#p5_loading{
  position: absolute;
  background-color:#c0d58a;
  width: 100vw;
  height: 100vh;  
}

#p5_loading img{
  position: absolute;
  top: 50%;
  left: 50%;
  width: 25vw;
  transform: translate(-50%,-50%);
}

#p5_loading_intro{
  position: absolute;
  background-color:#c0d58a;
  width: 100vw;
  height: 100vh;
  z-index: 99999999;  
}

#p5_loading_intro img{
  position: absolute;
  top: 50%;
  left: 50%;
  width: 25vw;
  transform: translate(-50%,-50%);
}