#videoWindow {
    position: absolute;
    width: 100vw;
    height: 100vh;
    top:50%;
    left:50%;
    z-index: 99999;
    transform: translate(-50%, -50%);
    background-color: rgba(47, 56, 27, 0.8);
}

#videoWindow #videoContainer {
    width: 741px;
    height: 537px;
    z-index: 99997;
    position: relative;
    margin: auto;

}

#videoWindow #videoContainer video{
    position: absolute;
    width: 670px;
    top:50.7%;
    left:50%;        
    transform: translate(-50%, -50%) scale(1.05);
    z-index: 99997;
}

#videoWindow #skipButton{
    width: 102px; 
    height:53px; 
    position: absolute;
    top: 30px;
    right: 30px;
    background-repeat: no-repeat;
    background-size: contain;
    z-index: 99997;
}