.Menu .buttons{
    padding: 4px;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    z-index: 9;
    width: 200px;
}

.Menu .buttons button{
    padding: 4px;
    z-index: 9;
    width: 100%;
    margin-bottom: 10px;
    text-align: center;
    font-size: 2em;
    background-color: #90C418;
    border-radius: 50px;
}

.Menu{
    position:absolute;
    left: 0px;
    top: 0px;  
    transform: translate(-50%, -50%);
    
}

.scoreBox{
    position:absolute;
    touch-action: none;
    pointer-events: none;
    z-index: 9999;
    width: 200px;
    height:49px;
    transform:translate(-50%, -50%) scale(1);
    background-size: contain;
    background-repeat: no-repeat;
}

.scoreBox .icono{
    display: inline-block;
    height: 62px;
    width: 65px;
    position: relative;
    left: -40px;
    top:-8px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
}

.scoreBox .texto{
    display: inline-block;  
    position: relative;
    left: -40px;
    top:10px;
    width: 79px;
    height: 62px;
    text-align: center;
    color: white;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 24px;
}

#menuContainer {
    width: 100vw;
    height: 100vh;
}

img{
    user-drag: none; 
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.personaje-loop {
    width: 256px;
    height: 256px;
    background-position: 0px 0px;
    transform: translate(-50%,-50%) scale(0.8);
    animation: 
      playv 1.5s steps(8) infinite,
      playh 1.5s steps(8) infinite;
  }
  
  
  @keyframes playv {
    0% { background-position-y: 0px; }
    100% { background-position-y: -2048px; }
  }
  
  @keyframes playh {
    0% { background-position-x: 0px; }
    100% { background-position-x: -2048px; }
  }


